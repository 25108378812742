<template>
    <el-main>
        <el-form ref="form" :model="addForm" :rules="rules" label-width="160px">
            <el-form-item label="活动名称：" prop="title">
                <el-input style="width: 400px" v-model="addForm.title" :disabled="status == 1 || status == 2 ? !1 : !0"
                    placeholder="请输入活动名称，不超过20个字"></el-input>
            </el-form-item>
            <el-form-item class="date" label="活动时间：">
                <el-form-item prop="start_time">
                    <el-date-picker v-model="addForm.start_time" :picker-options="pickerOptions"
                        :disabled="status == 2 ? !1 : !0" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                        placeholder="选择日期时间"></el-date-picker>
                </el-form-item>
                &nbsp;至&nbsp;
                <el-form-item prop="end_time">
                    <el-date-picker v-model="addForm.end_time" :picker-options="pickerOptions"
                        :disabled="status == 1 || status == 2 ? !1 : !0" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                        placeholder="选择日期时间"></el-date-picker>
                </el-form-item>
            </el-form-item>
            <el-form-item label="拼团商品：" prop="goods_id">
                <el-input style="display: none" v-model="addForm.goods_id"></el-input>
                <el-button type="text" :disabled="status == 2 ? !1 : !0" @click="is_goods = !0">选择商品</el-button>
                <el-table style="width: 500px" v-if="goodsInfo.id" :data="goodsInfo.goods"
                    :header-cell-style="{ 'background-color': '#F8F9FA' }">
                    <el-table-column prop="goods_name" label="商品" width="260px">
                        <template v-slot="{ row }">
                            <div class="goods-info">
                                <el-image style="width: 50px; height: 50px" :src="row.goods_picture"></el-image>
                                <span>{{ row.goods_name }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_price" label="价格"></el-table-column>
                    <el-table-column label="操作">
                        <template>
                            <el-button type="text" :disabled="status == 2 ? !1 : !0" @click="goodsInfo = {}">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item v-if="goodsInfo.id" label="拼团价设置：">
                <el-table style="width: 560px" v-if="goodsInfo.id" :data="goodsInfo.goods_spec"
                    :header-cell-style="{ 'background-color': '#F8F9FA' }">
                    <el-table-column prop="price" label="原价（元）"></el-table-column>
                    <el-table-column v-if="goodsInfo.is_spec" prop="title" label="规格"></el-table-column>
                    <el-table-column prop="group_price" label="拼团价（元）">
                        <template v-slot="{ row }">
                            <el-input v-model="row.group_price" :disabled="status == 2 ? !1 : !0" size="small">
                                <template slot="prepend">￥</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="captain_price" label="团长价（元）">
                        <template v-slot="{ row }">
                            <el-input v-model="row.captain_price" :disabled="status == 2 ? !1 : !0" size="small">
                                <template slot="prepend">￥</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stock" label="库存" width="70px"></el-table-column>
                </el-table>
                <div v-if="goodsInfo.is_spec" class="batch">
                    <span style="margin: 0">批量输入：</span>
                    <el-input v-model="batch_group_price" :disabled="status == 2 ? !1 : !0" size="small"
                        placeholder="拼团价"></el-input>
                    <el-input v-model="batch_captain_price" :disabled="status == 2 ? !1 : !0" size="small"
                        placeholder="团长价"></el-input>
                    <el-button type="primary" size="small" @click="batchIput">确认</el-button>
                    <el-button size="small" @click="(batch_group_price = ''), (batch_captain_price = '')">取消</el-button>
                </div>
            </el-form-item>
            <el-form-item label="拼团有效期：" prop="time_over_day">
                <el-select v-model="addForm.time_over_day" :disabled="status == 2 ? !1 : !0">
                    <el-option v-for="item in 31" :key="item" :label="item - 1 < 10 ? '0' + (item - 1) : item - 1"
                        :value="item - 1 < 10 ? '0' + (item - 1) : item - 1"></el-option>
                </el-select>
                <span>天</span>
                <el-select v-model="addForm.time_over_hour" :disabled="status == 2 ? !1 : !0">
                    <el-option v-for="item in 24" :key="item" :label="item - 1 < 10 ? '0' + (item - 1) : item - 1"
                        :value="item - 1 < 10 ? '0' + (item - 1) : item - 1"></el-option>
                </el-select>
                <span>时</span>
                <el-select v-model="addForm.time_over_minute" :disabled="status == 2 ? !1 : !0">
                    <el-option v-for="item in 60" :key="item" :label="item - 1 < 10 ? '0' + (item - 1) : item - 1"
                        :value="item - 1 < 10 ? '0' + (item - 1) : item - 1"></el-option>
                </el-select>
                <span>分</span>
                <div class="tips">
                    <p>拼团有效期需在15分钟至30天之间</p>
                    <p>若设置1日，用户开团后，需要在1日内成团，超时则拼团失败</p>
                </div>
            </el-form-item>
            <el-form-item label="参团人数：" prop="limit_buy_num">
                <el-input style="width: 150px" v-model="addForm.limit_buy_num" :disabled="status == 2 ? !1 : !0"></el-input>
                <span>人</span>
            </el-form-item>
            <el-form-item label="用户可进行的团数：" prop="limit_group_num">
                <el-input style="width: 150px" v-model="addForm.limit_group_num"
                    :disabled="status == 2 ? !1 : !0"></el-input>
            </el-form-item>
            <!--<el-form-item label="参团时可购买件数：" prop="limit_goods_num">
        <el-input style="width: 150px" v-model="addForm.limit_goods_num" :disabled="status == 2 ? !1 : !0"></el-input>
      </el-form-item>-->
            <el-form-item label="优惠叠加：">
                <el-radio-group v-model="addForm.is_stack" :disabled="status == 2 ? !1 : !0">
                    <el-radio :label="0">不叠加其他营销活动</el-radio>
                    <el-radio :label="1">叠加其他营销活动</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if="addForm.is_stack" label="可叠加活动：" prop="activity_info">
                <el-checkbox-group v-model="addForm.activity_info" :disabled="status == 2 ? !1 : !0">
                    <el-checkbox label="1" name="activity_info">优惠券</el-checkbox>
                    <el-checkbox label="2" name="activity_info">余额抵扣</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="凑团：">
                <el-checkbox v-model="addForm.is_together" :disabled="status == 2 ? !1 : !0" true-label="1"
                    false-label="0">开启</el-checkbox>
                <div class="tips">
                    <p style="margin: 0">开启凑团后，活动商品详情页展示未成团的团列表，买家可以任选一个团参团，提升成团率。</p>
                </div>
            </el-form-item>
            <el-form-item label="模拟成团：">
                <el-checkbox v-model="addForm.simulated_agglomeration" :disabled="status == 2 ? !1 : !0" :true-label="1"
                    :false-label="0">开启</el-checkbox>
                <template v-if="addForm.simulated_agglomeration == 1">
                    <div class="member-list">
                        <div class="member-box" v-for="(item, index) in addForm.simulated_person" :key="index">
                            <el-image :src="item.avatar"></el-image>
                            <i class="el-icon-circle-close" @click="addForm.simulated_person.splice(index, 1)"></i>
                        </div>
                    </div>
                    <div>
                        <el-button type="text" @click="addMember" :disabled="status == 2 ? !1 : !0">添加成员</el-button>
                        <el-dialog title="选择成员" :visible.sync="showAddMember" width="52%">
                            <list-Components v-if="showAddMember" :styleMethon="8"
                                @getListItem="getMemberInfo"></list-Components>
                        </el-dialog>
                    </div>
                </template>
                <div class="tips">
                    <p style="margin: 0">开启模拟成团后，系统将会模拟“匿名买家”凑满该团，仅需对真实拼团买家发货。建议合理开启，以提高成团率。</p>
                </div>
            </el-form-item>
            <el-form-item label="拼团购买包邮：">
                <el-checkbox v-model="addForm.is_free_shipping" :disabled="status == 2 ? !1 : !0" true-label="1"
                    false-label="0">开启</el-checkbox>
            </el-form-item>
        </el-form>
        <Goods v-if="is_goods" @getGoodsInfo="getGoodsInfo"></Goods>
        <Preservation v-if="status == 1 || status == 2" @preservation="submit">
            <router-link to="/extension/group/list" slot="return" style="margin-right: 10px">
                <el-button>返回</el-button>
            </router-link>
        </Preservation>
        <Test></Test>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import Goods from '../components/goods';
import { getDateformat } from '@/util/getDate';
import listComponents from '@/components/listComponents';
export default {
    components: {
        Preservation,
        Goods,
        listComponents,
    },
    data () {
        const verifyDay = (rule, value, callback) => {
            if (Number(value) > 0 || Number(this.addForm.time_over_hour) > 0 || Number(this.addForm.time_over_minute) >= 15) {
                callback();
            } else {
                callback(new Error('拼团有效期需在15分钟至30天之内'));
            }
        };
        const verifyTitle = (rule, value, callback) => {
            if (value.length > 20) {
                callback(new Error('请输入20个字以内的活动名称'));
            } else {
                callback();
            }
        };
        return {
            id: '',
            showAddMember: !1,
            addForm: {
                goods_id: '',
                title: '',
                time_over_day: '00',
                time_over_hour: '00',
                time_over_minute: '00',
                limit_buy_num: '',
                limit_group_num: '',
                limit_goods_num: '1',
                start_time: '',
                end_time: '',
                is_stack: 0,
                is_together: '1',
                simulated_agglomeration: 0,
                is_free_shipping: '0',
                activity_info: [],
                group_goods: [],
                simulated_person: [],
            },
            pickerOptions: {
                disabledDate (val) {
                    return val.getTime() < new Date().getTime() - 86400000;
                },
            },
            rules: {
                goods_id: [
                    {
                        required: true,
                        message: '请选择拼团商品',
                        trigger: 'change',
                    },
                ],
                title: [
                    {
                        required: true,
                        message: '请填写活动名称',
                        trigger: 'blur',
                    },
                    {
                        validator: verifyTitle,
                        trigger: 'blur',
                    },
                ],
                start_time: [
                    {
                        required: true,
                        message: '请添加活动开始时间',
                        trigger: 'blur',
                    },
                ],
                end_time: [
                    {
                        required: true,
                        message: '请添加活动结束时间',
                        trigger: 'blur',
                    },
                ],
                time_over_day: [
                    {
                        validator: verifyDay,
                        trigger: 'blur',
                    },
                ],
                limit_buy_num: [
                    {
                        required: true,
                        message: '请填写参团人数',
                        trigger: 'blur',
                    },
                ],
                limit_group_num: [
                    {
                        required: true,
                        message: '请填写用户可进行的团数',
                        trigger: 'blur',
                    },
                ],
                //limit_goods_num: [
                //    {
                //        required: true,
                //        message: '请填写参团时可购买件数',
                //        trigger: 'blur',
                //    },
                //],
                activity_info: [
                    {
                        type: 'array',
                        required: true,
                        message: '请勾选至少1个活动类型，或设置不叠加其它营销活动。',
                        trigger: 'change',
                    },
                ],
            },
            is_goods: !1,
            goodsInfo: {},
            batch_group_price: '',
            batch_captain_price: '',
            status: 2,
        };
    },
    created () {
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.is_edit = !0;
            this.getDetail();
        }
    },
    methods: {
        addMember () {
            // if(!this.addForm.limit_buy_num) return this.$message.warning("请先填写参团人数")
            // if(this.addForm.simulated_person.length >= this.addForm.limit_buy_num - 1){}
            this.showAddMember = !0;
        },
        getMemberInfo (val) {
            this.addForm.simulated_person.push(val.user_info);
            this.showAddMember = !1;
        },
        getDetail () {
            this.$axios
                .post(this.$api.group.info, {
                    id: this.id,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.goodsInfo = res.result.goods_info;
                        let info = res.result.info;
                        let addForm = this.addForm;
                        if (this.goodsInfo.is_spec) {
                            this.goodsInfo.goods_spec = res.result.goods_sku_list;
                        } else {
                            this.goodsInfo.goods_spec = [
                                {
                                    price: this.goodsInfo.goods_price,
                                    group_price: this.goodsInfo.group_price,
                                    captain_price: this.goodsInfo.captain_price,
                                    stock: this.goodsInfo.goods_stock,
                                },
                            ];
                        }
                        this.goodsInfo.goods = [
                            {
                                goods_name: this.goodsInfo.goods_name,
                                goods_price: this.goodsInfo.goods_price,
                                goods_picture: this.goodsInfo.goods_picture,
                            },
                        ];
                        if (info.is_stack) {
                            let activity_info = info.activity_info || {};
                            if (activity_info.coupon) {
                                addForm.activity_info.push('1');
                            }
                            if (activity_info.credit) {
                                addForm.activity_info.push('2');
                            }
                        }
                        let tempTime = parseInt(new Date().getTime() / 1000);
                        if (tempTime > info.start_time) {
                            this.status = 1;
                        } else {
                            this.status = 2;
                        }
                        if (tempTime > info.end_time) this.status = 3;
                        addForm.goods_id = info.goods_id;
                        addForm.title = info.title;
                        addForm.start_time = getDateformat(info.start_time);
                        addForm.end_time = getDateformat(info.end_time);
                        addForm.time_over_day = info.time_over_day < 10 ? '0' + info.time_over_day : info.time_over_day;
                        addForm.time_over_hour = info.time_over_hour < 10 ? '0' + info.time_over_hour : info.time_over_hour;
                        addForm.time_over_minute = info.time_over_minute < 10 ? '0' + info.time_over_minute : info.time_over_minute;
                        addForm.limit_buy_num = info.limit_buy_num;
                        addForm.limit_goods_num = info.limit_goods_num;
                        addForm.limit_group_num = info.limit_group_num;
                        addForm.is_stack = info.is_stack;
                        addForm.is_togetherv = String(info.is_together);
                        addForm.simulated_agglomeration = String(info.simulated_agglomeration);
                        addForm.is_free_shipping = String(info.is_free_shipping);
                        addForm.simulated_person = info.simulated_person;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        submit () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let addForm = Object.assign({}, this.addForm);
                    let goodsInfo = this.goodsInfo;
                    let url = this.id ? this.$api.group.edit : this.$api.group.add;
                    addForm.time_over_day = Number(addForm.time_over_day);
                    addForm.time_over_hour = Number(addForm.time_over_hour);
                    addForm.time_over_minute = Number(addForm.time_over_minute);
                    if (addForm.simulated_agglomeration) {
                        if (!addForm.simulated_person.length) return this.$message.warning('请添加模拟成员');
                        if (addForm.simulated_person.length < addForm.limit_buy_num - 1) return this.$message.warning(`至少需要添加${addForm.limit_buy_num - 1}个模拟成员`);
                        let arr = [];
                        for (let i in addForm.simulated_person) {
                            arr.push(addForm.simulated_person[i].id);
                        }
                        addForm.simulated_person = arr;
                    }
                    //是否多规格
                    if (goodsInfo.is_spec) {
                        let arr = [];
                        goodsInfo.goods_spec.map(item => {
                            arr.push({
                                goods_id: addForm.goods_id,
                                group_price: item.group_price,
                                captain_price: item.captain_price,
                                goods_sku_id: item.id,
                            });
                        });
                        addForm.group_goods = arr;
                    } else {
                        addForm.group_goods = [
                            {
                                goods_id: addForm.goods_id,
                                group_price: goodsInfo.goods_spec[0].group_price,
                                captain_price: goodsInfo.goods_spec[0].captain_price,
                                goods_sku_id: 0,
                            },
                        ];
                    }
                    for (let i in addForm.group_goods) {
                        if (!addForm.group_goods[i].group_price) return this.$message.warning('请填写拼团价格');
                        if (!addForm.group_goods[i].captain_price) return this.$message.warning('请填写团长价格');
                    }

                    if (addForm.is_stack) {
                        let obj = {};
                        for (let i in addForm.activity_info) {
                            if (addForm.activity_info[i] == 1) {
                                obj.coupon = 1;
                            }
                            if (addForm.activity_info[i] == 2) {
                                obj.credit = 1;
                            }
                        }
                        addForm.activity_info = obj;
                    }
                    if (this.id) addForm.id = this.id;
                    delete addForm.goods_id;
                    this.$axios.post(url, addForm).then(res => {
                        if (res.code == 0) {
                            this.$message.success(this.id ? '编辑成功' : '添加成功');
                            this.$router.push('/extension/group/list');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        getGoodsInfo (val) {
            this.is_goods = !1;
            val.goods = [
                {
                    goods_name: val.goods_name,
                    goods_price: val.goods_price,
                    goods_picture: val.goods_picture,
                },
            ];
            if (val.is_spec) {
                val.goods_spec.map(item => {
                    item.group_price = '';
                    item.captain_price = '';
                });
            } else {
                val.goods_spec = [
                    {
                        price: val.goods_price,
                        group_price: '',
                        captain_price: '',
                        stock: val.goods_stock,
                    },
                ];
            }
            this.addForm.goods_id = val.id;
            this.goodsInfo = val;
        },
        //批量输入
        batchIput () {
            if (this.batch_group_price) {
                this.goodsInfo.goods_spec.map(item => (item.group_price = this.batch_group_price));
            }
            if (this.batch_captain_price) {
                this.goodsInfo.goods_spec.map(item => (item.captain_price = this.batch_captain_price));
            }
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
    padding-bottom: 50px;

    .el-form {
        .el-form-item {
            .member-list {
                display: flex;

                .member-box {
                    position: relative;

                    .el-image {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        margin-right: 14px;
                    }

                    i {
                        position: absolute;
                        z-index: 3;
                        top: 0;
                        right: 0;
                        font-size: 20px;
                        cursor: pointer;
                    }
                }
            }

            .el-select {
                width: 100px;
            }

            span {
                margin: 0 10px;
            }

            .tips {
                p {
                    color: #999;
                    height: 20px;
                    line-height: 20px;
                    font-size: 12px;

                    &:nth-child(1) {
                        margin-top: 10px;
                    }
                }
            }

            /deep/ .el-table th {
                padding: 3px 0;
            }

            /deep/ .el-input-group__prepend {
                padding: 0 5px;
            }

            .batch {
                span {
                    color: #606266;
                }

                .el-input {
                    width: 100px;
                    margin-right: 10px;
                }
            }
        }

        .goods-info {
            display: flex;
            align-items: center;

            span {
                flex: 1;
            }
        }

        /deep/ .date>.el-form-item__content {
            display: flex;
        }
    }
}
</style>
